// Components
import Button, { EButtonSize, EButtonType, EButtonVariant } from "Components/Elements/Button";
import InputField from "Components/Elements/Form/Elements/TextField";
import BasePage from "Components/Pages/Base";
import AuthTemplate from "Components/PageTemplates/AuthTemplate/AuthTemplate";

// Styles
import classes from "./classes.module.scss";
import Form, { IApiFormErrors } from "Components/Elements/Form";
import I18n from "Components/Elements/I18n";
import ResetPassword from "Api/NotIT/AppAuth/ResetPassword";
import { NavigateFunction, URLSearchParamsInit, useNavigate, useSearchParams } from "react-router-dom";
import Module from "Components/Elements/Module";
import Modal from "Components/Elements/Modal";

type IProps = {};
type IClassProps = IProps & {
	searchParams: URLSearchParams;
	setSearchParams: (
		nextInit: URLSearchParamsInit,
		navigateOptions?:
			| {
					replace?: boolean | undefined;
					state?: any;
			  }
			| undefined,
	) => void;
	navigate: NavigateFunction;
};
type IState = {
	loading: boolean;
	success: boolean;
};

class ChangePassClass extends BasePage<IClassProps, IState> {
	private static errorToTextMap: Record<string, string> = {
		INVALID_EMAIL: I18n.config.pages.change_password.errors.invalid_email,
		PASSWORD_MISSMATCH: I18n.config.pages.change_password.errors.password_missmatch,
		BAD_CODE: I18n.config.pages.change_password.errors.bad_code,
		NO_CODE: I18n.config.pages.change_password.errors.no_code,
		EXPIRED_CODE: I18n.config.pages.change_password.errors.expired_code,
	};

	public constructor(props: IClassProps) {
		super(props);
		this.state = {
			loading: false,
			success: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.redirectToLogin = this.redirectToLogin.bind(this);
	}

	public override async componentDidMount(): Promise<void> {
		const { searchParams } = this.props;
		const code = searchParams.get("code");
		const email = searchParams.get("email");
		if (!code || !email) {
			this.setState({ loading: false }, () => {
				this.props.navigate(Module.config.pages.ResetPassword.props.path);
			});
		}
	}

	public override render(): JSX.Element {
		return (
			<>
				<AuthTemplate title="Changer votre mot de passe">
					<div className={classes["root"]}>
						<h1 className={classes["title"]}>Changement de mot passe</h1>

						<div className={classes["description"]}>Entrez votre nouveau mot de passe.</div>

						<Form onSubmit={this.handleSubmit} className={classes["form"]}>
							<InputField containerClassName={classes["fields"]} required name="password" type="password" placeholder={"Mot de passe"} />

							<InputField
								containerClassName={classes["fields"]}
								required
								name="confirmPassword"
								type="password"
								placeholder={"Confirmation du Mot de passe"}
							/>

							<InputField containerClassName={classes["hidden-input"]} type="hidden" name="hidden" onChange={() => {}} />

							<div className={classes["button_container"]}>
								<Button variant={EButtonVariant.PURPLE} size={EButtonSize.LARGE} type={EButtonType.SUBMIT}>
									Changer le mot de passe
								</Button>
							</div>
						</Form>
					</div>
				</AuthTemplate>
				{this.state.success && (
					<Modal header={"Votre mot de passe a bien été changé"} hasCloseButton onClose={this.redirectToLogin}>
						<div>Fermer cette popup pour être redirigé sur la page de connection</div>
					</Modal>
				)}
			</>
		);
	}

	private async handleSubmit(
		e: React.FormEvent<HTMLFormElement> | null,
		values: Record<string, string>,
		onApiErrors: (apiFormErrors: IApiFormErrors | null) => void,
	) {
		try {
			const { password, confirmPassword } = values;
			const email = this.props.searchParams.get("email");
			const code = this.props.searchParams.get("code");

			if (!password || !confirmPassword || !email || !code) return;
			await ResetPassword.getInstance().changePassword({ email, password, confirmPassword, code });
			this.setState({ success: true });
		} catch (error: any) {
			console.log(error.error.message);
			onApiErrors({
				hidden: I18n.translate(
					ChangePassClass.errorToTextMap[error.error.message] ?? I18n.config.pages.change_password.errors.error_change_password,
				),
			});
		}
	}

	private redirectToLogin(): void {
		this.props.navigate(Module.config.pages.Login.props.path);
	}
}

export default function ChangePass(props: IProps) {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	return <ChangePassClass navigate={navigate} searchParams={searchParams} setSearchParams={setSearchParams} {...props} />;
}
