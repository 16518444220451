// Components
import BasePage from "Components/Pages/Base";
import { Navigate, NavigateFunction, useNavigate } from "react-router-dom";

// Styles
import Module from "Components/Elements/Module";

type IProps = {};
type IPropsClass = IProps & {
	navigate: NavigateFunction;
};
type IState = {};

class LogoutClass extends BasePage<IPropsClass, IState> {
	public override render(): JSX.Element {
		return <Navigate to={Module.config.pages.Login.props.path} />;
	}
}

export default function Logout(props: IProps) {
	const navigate = useNavigate();

	return <LogoutClass navigate={navigate} {...props} />;
}
