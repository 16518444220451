import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";

export type IResetPasswordCodeBody = {
	email: string;
};
export type IResetPasswordCodeResponse = boolean;

export type IChangePasswordBody = {
	email: string;
	password: string;
	confirmPassword: string;
	code: string;
};
export type IChangePasswordResponse = boolean;

export default class ResetPassword extends BaseApiService {
	private static instance: ResetPassword;
	protected static readonly baseUrl = Config.getInstance()
		.get()
		.api.notIT.concat("/app-auth/", Config.getInstance().get().app.concat("/resetPassword"));

	public static getInstance() {
		if (!ResetPassword.instance) {
			ResetPassword.instance = new ResetPassword();
		}

		return ResetPassword.instance;
	}

	public async sendCode(body: IResetPasswordCodeBody): Promise<IResetPasswordCodeResponse> {
		const url = new URL(`${ResetPassword.baseUrl}/sendCode`);
		try {
			return this.postRequest(url, body);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async changePassword(body: IChangePasswordBody): Promise<IChangePasswordResponse> {
		const url = new URL(`${ResetPassword.baseUrl}/changePassword`);
		try {
			return this.postRequest(url, body);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
