import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";
import { JwtPair } from "Entities/AuthFactory/AccessToken";
import { IAppUser } from "Entities/AuthFactory/User";

export type ISigninBody = {
	email: string;
	password: string;
};

export interface ISigninResponse {
	jwtPair: JwtPair;
	user: IAppUser;
}

export default class Signin extends BaseApiService {
	private static instance: Signin;
	protected static readonly baseUrl = Config.getInstance()
		.get()
		.api.notIT.concat("/app-auth/", Config.getInstance().get().app.concat("/signin"));

	public static getInstance() {
		if (!Signin.instance) {
			Signin.instance = new Signin();
		}

		return Signin.instance;
	}

	public async post(body: ISigninBody): Promise<ISigninResponse> {
		const url = new URL(Signin.baseUrl);
		try {
			return this.postRequest(url, body);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
