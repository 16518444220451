import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";
import { JwtPair } from "Entities/AuthFactory/AccessToken";
import { IAppUser } from "Entities/AuthFactory/User";
import { IAppOffice } from "Entities/AuthFactory/Office";

export type IRegisterBody = {
	email: string;
	password: string;
	confirmPassword: string;
	officeDenomination: string;
	officeAddress: string;
	officeCity: string;
	officeZipCode: string;
	officeCRPCEN: string;
	lastName: string;
	firstName: string;
	phoneNumber: string;
};

export interface IRegisterResponse {
	jwtPair: JwtPair;
	user: IAppUser;
	office: IAppOffice;
}

export default class Register extends BaseApiService {
	private static instance: Register;
	protected static readonly baseUrl = Config.getInstance()
		.get()
		.api.notIT.concat("/app-auth/", Config.getInstance().get().app.concat("/register"));

	public static getInstance() {
		if (!Register.instance) {
			Register.instance = new Register();
		}

		return Register.instance;
	}

	public async post(body: IRegisterBody): Promise<IRegisterResponse> {
		const url = new URL(Register.baseUrl);
		try {
			return this.postRequest(url, body);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
