import classNames from "classnames";
import React, { ReactNode } from "react";
import classes from "./classes.module.scss";

type IProps = {
	display: boolean;
	title?: React.ReactNode;
	event: React.MouseEvent<HTMLElement> | null;
	children?: ReactNode;
};

export default class TooltipContent extends React.Component<IProps> {
	public override render(): JSX.Element {
		const mousePositionX = this.props.event?.clientX;
		const mousePositionY = this.props.event?.clientY;
		const right = window.innerWidth - (mousePositionX ?? 0) - 52;
		const bottom = window.innerHeight - (mousePositionY ?? 0) + 11;
		return (
			<div
				style={{ bottom: bottom + "px", right: right + "px" }}
				className={classNames(classes["root"], { [classes["show"]!]: this.props.display })}>
				<div className={classes["root-content"]}>
					{this.props.title && <div className={classes["title"]}>{this.props.title}</div>}
					{this.props.children}
				</div>
				<svg className={classes["carrot"]} width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M12.5 14.4231L1.26091e-06 4.49015e-06L25 6.67572e-06L12.5 14.4231Z" />
				</svg>
			</div>
		);
	}
}
