import React from "react";
import TooltipContent from "./Content";

type IProps = {
	icon: JSX.Element;
	title?: string | JSX.Element;
	text?: string | JSX.Element;
	className?: string;
	isNotFlex?: boolean;
};

type IState = {
	showContent: boolean;
	event: React.MouseEvent<HTMLElement> | null;
};

export default class Tooltip extends React.Component<IProps, IState> {
	static defaultProps: Partial<IProps> = {
		isNotFlex: false,
	};

	public constructor(props: IProps) {
		super(props);
		this.state = {
			showContent: false,
			event: null,
		};
		this.togglePopup = this.togglePopup.bind(this);
		this.moovePopup = this.moovePopup.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<>
				<span className={this.props.className} style={!this.props.isNotFlex ? { display: "flex" } : {}} onMouseEnter={this.togglePopup} onMouseLeave={this.togglePopup} onMouseMove={this.moovePopup}>
					{this.props.icon}
				</span>
				<TooltipContent title={this.props.title} event={this.state.event} display={this.state.showContent}>
					{this.props.text}
				</TooltipContent>
			</>
		);
	}

	private togglePopup(e: React.MouseEvent<HTMLSpanElement>): void {
		this.setState({
			showContent: !this.state.showContent,
			event: e,
		});
	}

	private moovePopup(e: React.MouseEvent<HTMLSpanElement>): void {
		this.setState({
			event: e,
		});
	}
}
