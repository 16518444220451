import React, { ReactNode } from "react";
import classNames from "classnames";

// Styles
import classes from "./classes.module.scss";

export enum EButtonVariant {
	PURPLE = "purple",
	GHOST = "ghost",
	RED = "red",
	ORANGE = "orange",
}

export enum EButtonSize {
	SMALL = "s",
	MEDIUM = "m",
	LARGE = "l",
	XTRALARGE = "xl",
}

export enum EButtonType {
	BUTTON = "button",
	SUBMIT = "submit",
}

type IProps = {
	variant: EButtonVariant;
	onClick?: () => void;
	size?: EButtonSize;
	disabled?: boolean;
	type?: EButtonType;
	className?: string;
	children?: ReactNode;
};
type IState = {};

export default class Button extends React.Component<IProps, IState> {
	static defaultProps: IProps = {
		variant: EButtonVariant.PURPLE,
		disabled: false,
		size: EButtonSize.MEDIUM,
		type: EButtonType.BUTTON,
	};

	public override render(): JSX.Element {
		return (
			<button
				className={classNames(classes["root"], this.props.className, classes[this.props.variant])}
				type={this.props.type}
				disabled={this.props.disabled}
				onClick={this.props.onClick}>
				{this.props.children}
			</button>
		);
	}
}
