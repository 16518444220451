import React, { ReactNode } from "react";
import BaseField, { IError, IProps as IBaseFieldProps } from "./Elements/BaseField";

export type IBaseField = BaseField<IBaseFieldProps>;

export type IFormContext = {
	setField: (name: string, field: IBaseField) => void;
	unSetField: (name: string) => void;
	onFieldChange: (name: string, field: IBaseField) => void;
};

type IFields = {
	[key: string]: IBaseField;
};

export type IApiFormErrors = {
	[fieldName: string]: string;
};

export type IFormErrors = {
	[key: string]: {
		field: IBaseField;
		errors: IError[] | undefined;
	};
};

type IState = {};
type IProps = {
	onFieldChange?: (name: string, field: IBaseField, formRef: React.RefObject<HTMLFormElement>) => void;
	onSubmit?: (
		e: React.FormEvent<HTMLFormElement> | null,
		values: { [key: string]: string },
		onApiErrors: (apiFormErrors: IApiFormErrors | null) => void,
	) => void;
	onValidated?: () => void;
	onErrors?: (errors: IFormErrors) => void;
	/**
	 * @description Url, No redirection without action
	 */
	action?: string;
	className?: string;
	children?: ReactNode;
};

export const FormContext = React.createContext<IFormContext>({ setField: () => {}, unSetField: () => {}, onFieldChange: () => {} });

export default class Form extends React.Component<IProps, IState> {
	protected fields: IFields = {};
	private formRef: React.RefObject<HTMLFormElement>;

	constructor(props: IProps) {
		super(props);

		this.state = {};
		this.setField = this.setField.bind(this);
		this.unSetField = this.unSetField.bind(this);
		this.onFieldChange = this.onFieldChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onSubmitErrorApi = this.onSubmitErrorApi.bind(this);
		this.formRef = React.createRef();
	}

	public override render() {
		return (
			<FormContext.Provider
				value={{
					setField: this.setField,
					unSetField: this.unSetField,
					onFieldChange: this.onFieldChange,
				}}>
				<form className={this.props.className} ref={this.formRef} onSubmit={this.onSubmit} action={this.props.action ?? ""}>
					{this.props.children}
				</form>
			</FormContext.Provider>
		);
	}

	public onSubmit(e: React.FormEvent<HTMLFormElement> | null) {
		if (!this.props.action) e?.preventDefault();

		const errors = this.validate();

		if (errors) {
			e?.preventDefault();

			this.onErrors(errors);

			return { errors };
		}

		if (this.props.onValidated) this.props.onValidated();

		const elementsValues = this.getAllChildrenFields(e).reduce(
			(obj, element) => ({ ...obj, [element.getAttribute("name") ?? ""]: (element as any).value }),
			{},
		);

		if (this.props.onSubmit) {
			this.props.onSubmit(e, elementsValues, this.onSubmitErrorApi);
		}

		return { values: elementsValues };
	}

	protected onSubmitErrorApi(apiFormErrors: IApiFormErrors | null) {
		if (!apiFormErrors) return;
		const errors: IFormErrors = {};
		for (const [key, message] of Object.entries(apiFormErrors)) {
			if (!this.fields[key]) continue;
			this.fields[key]?.setErrors([
				{
					message,
					validator: "",
					value: this.fields[key]?.state.value ?? "",
					args: [],
				},
			]);
		}
		this.onErrors(errors);
	}

	protected validate() {
		const errors = Object.entries(this.fields)
			.map(([name, field]) => {
				return { name, validation: field.validate(), field };
			})
			.filter(({ validation }) => validation?.length);

		if (!errors.length) {
			return null;
		}

		const errorsObject: IFormErrors = {};
		errors.forEach(({ name, validation, field }) => (errorsObject[name] = { errors: validation, field }));
		return errorsObject;
	}

	protected onErrors(errors: IFormErrors) {
		if (this.props.onErrors) this.props.onErrors(errors);
	}

	protected setField(name: string, field: IBaseField) {
		this.fields[name] = field;
	}

	protected unSetField(name: string) {
		delete this.fields[name];
	}

	protected onFieldChange(name: string, field: IBaseField) {
		if (this.props.onFieldChange) this.props.onFieldChange(name, field, this.formRef);
	}

	private getAllChildrenFields(e: React.FormEvent<HTMLFormElement> | null): Element[] {
		return Array.from(((e?.target as HTMLFormElement) ?? this.formRef.current).elements);
	}
}
