// Components
import BasePage from "Components/Pages/Base";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate/DefaultTemplate";

// Styles
import classes from "./classes.module.scss";

export default class Home extends BasePage {
	public override render(): JSX.Element {
		return (
			<DefaultTemplate title="Home Page">
				<div className={classes["root"]}></div>
			</DefaultTemplate>
		);
	}
}
