// Components
import Button, { EButtonSize, EButtonType, EButtonVariant } from "Components/Elements/Button";
import InputField from "Components/Elements/Form/Elements/TextField";
import Module from "Components/Elements/Module";
import BasePage from "Components/Pages/Base";
import AuthTemplate from "Components/PageTemplates/AuthTemplate/AuthTemplate";
import { NavigateFunction, NavLink, useNavigate } from "react-router-dom";

// Styles
import Signin from "Api/NotIT/AppAuth/Signin";
import Form, { IApiFormErrors } from "Components/Elements/Form";
import I18n from "Components/Elements/I18n";
import JwtStore from "Stores/JwtStore";
import classes from "./classes.module.scss";
import Presign from "Api/NotIT/AppAuth/Presign";

type IProps = {};
type IPropsClass = IProps & {
	navigate: NavigateFunction;
};
type IState = {
	loading: boolean;
};

class LoginClass extends BasePage<IPropsClass, IState> {
	public constructor(props: IPropsClass) {
		super(props);
		this.state = {
			loading: false,
		};

		this.onSubmit = this.onSubmit.bind(this);
	}

	public override async componentDidMount(): Promise<void> {
		const { jwtPair } = await Presign.getInstance().post();
		JwtStore.getInstance().setJwtPair(jwtPair);
	}

	public override render(): JSX.Element {
		return (
			<AuthTemplate title="Connexion">
				<div className={classes["root"]}>
					<h1 className={classes["title"]}>Connexion espace professionnel</h1>

					<Form onSubmit={this.onSubmit} className={classes["form"]}>
						<InputField containerClassName={classes["fields"]} required name="email" type="email" placeholder={"Email"} emailOnly />

						<InputField containerClassName={classes["fields"]} required name="password" type="password" placeholder={"Mot de passe"} />

						<NavLink to={Module.config.pages.ResetPassword.props.path} className={classes["link-forgot"]}>
							Mot de passe oublié ?
						</NavLink>

						<InputField containerClassName={classes["hidden-input"]} type="hidden" name="hidden" onChange={() => {}} />

						<div className={classes["button-container"]}>
							<Button variant={EButtonVariant.PURPLE} size={EButtonSize.LARGE} type={EButtonType.SUBMIT}>
								Se connecter
							</Button>
							<NavLink to={Module.config.pages.SignUp.props.path} className={classes["link-register"]}>
								J'inscris mon office notarial
							</NavLink>
						</div>
					</Form>
				</div>
			</AuthTemplate>
		);
	}

	private async onSubmit(
		e: React.FormEvent<HTMLFormElement> | null,
		values: Record<string, string>,
		onApiErrors: (apiFormErrors: IApiFormErrors | null) => void,
	) {
		try {
			const { email, password } = values;
			if (!email || !password) return;
			const { jwtPair } = await Signin.getInstance().post({ email, password });
			JwtStore.getInstance().setJwtPair(jwtPair);
			this.props.navigate(Module.config.pages.Home.props.path.pathname, { replace: true });
		} catch (error: any) {
			onApiErrors({ hidden: I18n.translate(I18n.config.pages.login.errors.login_failed) });
		}
	}
}

export default function Login(props: IProps) {
	const navigate = useNavigate();

	return <LoginClass navigate={navigate} {...props} />;
}
