import React, { ChangeEvent, ReactNode } from "react";
import classNames from "classnames";
import PhoneInput, { Value } from "react-phone-number-input";

// Components
import { IValidationTypes } from "../../Validators";
import BaseField, { IError, IProps as IBaseFieldProps } from "../BaseField";
import I18nStore, { ELang } from "Components/Elements/I18n/I18nStore";

// Styles
import classes from "./classes.module.scss";
import "react-phone-number-input/style.css";

export type OnChangeEvent = ChangeEvent<HTMLInputElement>;

export type IProps = IValidationTypes &
	IBaseFieldProps & {
		type?: string;
		containerClassName?: string;
		className?: string;
		fieldRef?: React.RefObject<any>;
		accept?: string;
		onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
		onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
		step?: string;
		nativeMin?: string | number;
		nativeMax?: string | number;
		leftElement?: ReactNode;
		rightElement?: ReactNode;
		placeholder?: string;
		disabled?: boolean;
		disableErrors?: boolean;
		required?: boolean;
	};

type IState = {
	value: string;
	errors: IError[];
	isFocused: boolean;
};

export default class InputField extends BaseField<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.handleOnChange = this.handleOnChange.bind(this);
		this.handlePhoneChange = this.handlePhoneChange.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.toggleFocus = this.toggleFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);

		this.state = {
			errors: [],
			value: this.props.value || "",
			isFocused: false,
		};

		if (this.props.fieldRef) {
			this.fieldRef = this.props.fieldRef;
		}
	}

	public override render() {
		return (
			<div className={classNames(classes["root"], this.props.containerClassName)}>
				{this.props.leftElement && <div className={classes["left"]}>{this.props.leftElement}</div>}
				{this.renderInput()}
				{this.props.rightElement && <div className={classes["right"]}>{this.props.rightElement}</div>}
				{!this.props.disableErrors && this.autoRenderErrors()}
			</div>
		);
	}

	protected handleOnChange(event: ChangeEvent<HTMLInputElement & HTMLSelectElement & HTMLTextAreaElement>) {
		const numberRegExp = new RegExp("(^[0-9]*)(\\.{0,1})([0-9]*)$");
		const isValidNumber = numberRegExp.test(event.currentTarget.value);

		if (this.props.type !== "number" || isValidNumber) {
			this.onChange(event);
		}
	}

	private handlePhoneChange(value?: Value) {
		this.handleOnChange({
			currentTarget: {
				name: this.props.name,
				value,
			},
		} as ChangeEvent<HTMLInputElement & HTMLSelectElement & HTMLTextAreaElement>);
	}

	private renderInput() {
		if (this.props.type === "tel") {
			return (
				<PhoneInput
					international
					limitMaxLength
					ref={this.fieldRef}
					placeholder={this.props.placeholder}
					value={this.props.value}
					onChange={this.handlePhoneChange}
					className={classNames(classes["phone"], this.props.className)}
					defaultCountry={I18nStore.getInstance().lang === ELang.FR ? "FR" : "GB"}
				/>
			);
		}
		return (
			<>
				<input
					readOnly={this.props.isReadOnly}
					ref={this.fieldRef}
					onKeyDown={this.onKeyDown}
					type={this.props.type && this.props.type !== "number" ? this.props.type : "text"}
					value={this.props.value}
					defaultValue={this.props.defaultValue}
					onChange={this.handleOnChange}
					onBlur={this.onBlur}
					required={this.props.required}
					disabled={this.props.disabled}
					name={this.props.name}
					id={this.props.name}
					data-has-validation-errors={this.state.errors.length > 0}
					className={classNames(classes["input"], this.props.className, {
						[classes["error"]!]: this.state.errors.length > 0,
					})}
					accept={this.props.accept}
					step={this.props.step}
					min={this.props.nativeMin}
					max={this.props.nativeMax}
					onFocus={this.toggleFocus}
				/>
				{this.props.placeholder && (
					<span
						className={classNames(classes["placeholder"], {
							[classes["focused"]!]: this.state.isFocused,
						})}>
						{this.props.placeholder}
					</span>
				)}
			</>
		);
	}

	private toggleFocus() {
		if ((this.state.value && this.state.isFocused) || this.state.errors.length > 0) return;
		this.setState((prevState) => ({
			isFocused: !prevState.isFocused,
		}));
	}

	private onBlur(event: React.FocusEvent<HTMLInputElement>) {
		this.toggleFocus();
		this.props.onBlur?.(event);
	}

	private onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.key === "Enter") {
			e.preventDefault();
			return;
		}

		this.props.onKeyDown && this.props.onKeyDown(e);
	}
}
