import EventService from "Services/EventEmitter";

import fr from "Configs/I18n/fr.json";
import en from "Configs/I18n/en.json";

export enum ELang {
	EN = "en",
	FR = "fr",
}

type IVars = { [key: string]: string };

export type IKeyOfLng = keyof typeof en;

export type ILngType = {
	[key in string]: string | ILngType;
};

const lngs: { [key: string]: ILngType } = {
	fr,
	en,
};

export default class I18nStore {
	private static instance: I18nStore;
	private static readonly defaultLng = ELang.FR;
	private readonly event = new EventService();
	private cache = new Map<string, any>();

	private constructor() {
		I18nStore.instance = this;
		this.autoDetectAndSetLanguage();
	}

	private _assetDefault: ILngType = lngs[I18nStore.defaultLng]!;

	public get assetDefault() {
		return this._assetDefault;
	}

	private _asset: ILngType = lngs[ELang.FR]!;

	public get asset() {
		return this._asset;
	}

	public get lang() {
		return localStorage.getItem("lang") ?? I18nStore.defaultLng;
	}

	public static getInstance() {
		return I18nStore.instance ?? new this();
	}

	/**
	 * @returns removelistener callback
	 */
	public onChange(callback: (asset: ILngType) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public toggleTo(key: string) {
		this.switch(lngs[key]!, key);
		return this.asset;
	}

	public getTranslations(map: string | string[], vars?: IVars): string[] {
		const translations = [];

		if (typeof map === "string") {
			translations.push(this.translate(map, vars));
			return translations;
		}

		translations.push(
			...map.map((key) => {
				return this.translate(key, vars);
			}),
		);

		return translations;
	}

	public translate(key: string, vars: IVars = {}, lng?: ELang | undefined) {
		const cacheKey = key.concat(JSON.stringify(vars));

		const value = this.getCache(cacheKey);

		const asset = lng ? lngs[lng] || this.asset : this.asset;

		if (asset === this.asset && value !== undefined) return value;

		const assetValue: string | null = this.getFromObjectByKeyString(key, asset) ?? this.getFromObjectByKeyString(key, this.assetDefault);

		if (!assetValue) return key;

		const resultWithVariables = Object.keys(vars).reduce((str, key) => {
			const regex = new RegExp("\\{\\{" + key + "\\}\\}", "gmi");
			return str.replace(regex, vars[key] ?? "");
		}, assetValue);

		if (asset === this.asset) {
			this.setCache(cacheKey, resultWithVariables);
		}

		return resultWithVariables;
	}

	private switch(asset: ILngType, key: string) {
		this.storeLang(key);
		if (asset === this._asset) return;
		this._asset = asset;
		// whenever the application's language changes the localization cache is fully cleared:
		this.cache.clear();

		this.event.emit("change", { lang: key, ...this._asset });
	}

	private getFromObjectByKeyString(key: string, asset: { [key: string]: any }) {
		const keys = key.split(".");

		return keys.reduce((asset, key) => asset?.[key] ?? null, asset) as any;
	}

	private getCache(key: string): string | undefined {
		return this.cache.get(key);
	}

	private setCache(key: string, value: string) {
		this.cache.set(key, value);
	}

	private autoDetectAndSetLanguage() {
		// WARN: This should be uncommented if the client wants to use the browser language
		/*
		let lng: string = localStorage.getItem("lang") ?? window.navigator.language.split("-")[0]!;
		if (!lngs[lng]) lng = I18nStore.defaultLng;
		this.toggleTo(lng);
		*/
		this.toggleTo(I18nStore.defaultLng);
	}

	private storeLang(key: string) {
		const lng = localStorage.getItem("lang");
		if (!lng || lng !== key) localStorage.setItem("lang", key);
	}
}
