// Components
import Button, { EButtonVariant } from "Components/Elements/Button";
import Form, { IApiFormErrors } from "Components/Elements/Form";
import TextField from "Components/Elements/Form/Elements/TextField";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate/DefaultTemplate";
import BasePage from "../Base";

// Assets
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";

// Styles
import classes from "./classes.module.scss";

type IProps = {};
type IState = {
	isPersonalInformationsEditable: boolean;
};

export default class MyAccount extends BasePage<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			isPersonalInformationsEditable: false,
		};

		this.onSubmit = this.onSubmit.bind(this);
	}

	override render() {
		return (
			<DefaultTemplate title="Mon compte">
				<div className={classes["root"]}>
					<h1 className={classes["title"]}>Mon compte</h1>

					<div className={classes["content"]}>
						<div className={classes["form-container"]}>
							<h2 className={classes["sub-title"]}>Mes informations</h2>

							<Form onSubmit={this.onSubmit}>
								<TextField name="lastname" placeholder="Nom" containerClassName={classes["text-field-container"]} />
								<TextField name="firstname" placeholder="Prénom" containerClassName={classes["text-field-container"]} />
								<TextField name="email" placeholder="E-mail" containerClassName={classes["text-field-container"]} emailOnly />
								<TextField containerClassName={classes["text-field-container"]} placeholder="Numéro de téléphone" name="phone" />

								{this.state.isPersonalInformationsEditable ? (
									<Button variant={EButtonVariant.PURPLE}>Enregistrer</Button>
								) : (
									<span className={classes["edit-form"]}>
										Modifier <EditIcon />
									</span>
								)}
							</Form>
						</div>

						<div className={classes["divider"]} />

						<div className={classes["form-container"]}>
							<h2 className={classes["sub-title"]}>Mot de passe</h2>

							<Form onSubmit={this.onSubmit}>
								<TextField
									name="password"
									type="password"
									placeholder="Mot de passe"
									containerClassName={classes["text-field-container"]}
								/>
							</Form>
						</div>
					</div>
				</div>
			</DefaultTemplate>
		);
	}

	private onSubmit(
		e: React.FormEvent<HTMLFormElement> | null,
		values: Record<string, string>,
		onApiErrors: (apiFormErrors: IApiFormErrors | null) => void,
	) {
		console.log("submit");
	}
}
