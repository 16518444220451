import { IAppRule } from "./Rule";

export interface IAppRole {
	name: ERole;
	level: number;

	appRules: IAppRule[];
}

export enum ERole {
	SUPER_ADMIN = "super-admin",
	ADMIN = "admin",
	OFFICE = "office",
}
