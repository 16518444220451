// Components
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate/DefaultTemplate";
import BasePage from "../Base";

// Styles
import classes from "./classes.module.scss";

type IProps = {};
type IState = {};

export default class CollaboratorManagement extends BasePage<IProps, IState> {
	override render() {
		return (
			<DefaultTemplate title="CollaboratorManagement">
				<div className={classes["root"]}>CollaboratorManagement</div>
			</DefaultTemplate>
		);
	}
}
