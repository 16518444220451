import { ChangeEvent, Component, ReactNode } from "react";
import classNames from "classnames";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	onChange?: (value: boolean) => void;
	classname?: string;
	children?: ReactNode;
};
type IState = {};

export default class Checkbox extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.onChange = this.onChange.bind(this);
	}

	override render() {
		return (
			<label className={classNames(classes["root"], this.props.classname)}>
				<input type="checkbox" onChange={this.onChange} className={classes["checkbox"]} />
				{this.props.children}
			</label>
		);
	}

	private onChange(event: ChangeEvent<HTMLInputElement>) {
		const isChecked = event.target.checked;
		if (this.props.onChange) {
			this.props.onChange(isChecked);
		}
	}
}
