import React from "react";

// Assets
import NotITLogo from "assets/images/logos/not-it.png";
import NotITLogoBig from "assets/images/logos/not-it-big.png";

type IProps = {
	isBig?: boolean;
	classname?: string;
};
type IState = {};

export default class Logo extends React.Component<IProps, IState> {
	public override render(): JSX.Element {
		return <img src={this.props.isBig ? NotITLogoBig : NotITLogo} className={this.props.classname} alt="not.IT Logo" />;
	}
}
