// Components
import Button, { EButtonSize, EButtonType, EButtonVariant } from "Components/Elements/Button";
import InputField from "Components/Elements/Form/Elements/TextField";
import BasePage from "Components/Pages/Base";
import AuthTemplate from "Components/PageTemplates/AuthTemplate/AuthTemplate";

// Styles
import Register from "Api/NotIT/AppAuth/Register";
import { ReactComponent as TooltipIcon } from "assets/images/icons/tooltip-icon.svg";
import Checkbox from "Components/Elements/Checkbox";
import Form, { IApiFormErrors } from "Components/Elements/Form";
import I18n from "Components/Elements/I18n";
import Modal from "Components/Elements/Modal";
import Tooltip from "Components/Elements/Tooltip";
import classes from "./classes.module.scss";

type IProps = {};
type IState = {
	loading: boolean;
	success: boolean;

	hasAcceptedGTCU: boolean;
};

export default class SignUp extends BasePage<IProps, IState> {
	private static errorToTextMap: Record<string, string> = {
		INVALID_EMAIL: I18n.config.pages.sign_up.errors.invalid_email,
		USER_ALREADY_EXIST: I18n.config.pages.sign_up.errors.user_already_exist,
		OFFICE_ALREADY_EXIST: I18n.config.pages.sign_up.errors.office_already_exist,
		PASSWORD_MISSMATCH: I18n.config.pages.sign_up.errors.password_missmatch,
	};
	public constructor(props: IProps) {
		super(props);
		this.state = {
			loading: false,
			success: false,

			hasAcceptedGTCU: false,
		};

		this.toggleGTCU = this.toggleGTCU.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.refreshPage = this.refreshPage.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<>
				<AuthTemplate title="Connexion">
					<div className={classes["root"]}>
						<h1 className={classes["title"]}>Inscription</h1>
						<div className={classes["description"]}>
							Nous prenons la sécurité de nos clients très au sérieux.
							<br />
							Un formulaire d'inscription est nécessaire pour valider votre inscription.
						</div>
						lastName: string; firstName: string; phoneNumber: string;
						<Form onSubmit={this.handleSubmit} className={classes["form"]}>
							<h2> Informations de l'office notarial </h2>
							<InputField
								containerClassName={classes["fields"]}
								className={classes["input"]}
								required
								name="officeDenomination"
								type="text"
								placeholder="Dénomination de l'office"
							/>
							<InputField
								containerClassName={classes["fields"]}
								required
								name="officeAddress"
								type="text"
								placeholder={"Adresse de l'office"}
							/>
							<InputField containerClassName={classes["fields"]} required name="officeCity" type="text" placeholder={"Ville"} />
							<InputField containerClassName={classes["fields"]} required name="officeZipCode" type="text" placeholder={"Code Postal"} />
							<InputField containerClassName={classes["fields"]} required name="officeCRPCEN" type="text" placeholder={"CRPCEN Office"} />
							<h2> Informations personnelles </h2>
							<div className={classes["email_container"]}>
								<InputField containerClassName={classes["fields"]} required name="email" type="email" placeholder={"E-mail"} emailOnly />
								<Tooltip
									className={classes["tooltip"]}
									text={"Veuillez indiquer votre adresse e-mail professionnelle (@notaires.fr)"}
									icon={<TooltipIcon />}
									isNotFlex
								/>
							</div>
							<InputField containerClassName={classes["fields"]} required name="lastName" type="text" placeholder={"Nom"} />
							<InputField containerClassName={classes["fields"]} required name="firstName" type="text" placeholder={"Prénom"} />
							<InputField containerClassName={classes["fields"]} required name="phoneNumber" type="text" placeholder={"Téléphone"} />
							<InputField containerClassName={classes["fields"]} required name="password" type="password" placeholder={"Mot de passe"} />
							<p className={classes["password-restrictions"]}>Au moins 8 caractères dont 1 majuscule, 1 minuscule et 1 chiffre.</p>
							<InputField
								containerClassName={classes["fields"]}
								required
								name="confirmPassword"
								type="password"
								placeholder={"Confirmation mot de passe"}
							/>

							<Checkbox onChange={this.toggleGTCU} classname={classes["checkbox-label"]}>
								<span>
									En créant votre compte vous acceptez nos&nbsp;
									<a href="#lol" className={classes["underline-link"]}>
										Conditions Générales d'Utilisation
									</a>
								</span>
							</Checkbox>

							<div className={classes["button_container"]}>
								<Button variant={EButtonVariant.PURPLE} size={EButtonSize.LARGE} type={EButtonType.SUBMIT}>
									Finaliser mon inscription
								</Button>
							</div>

							<p className={classes["contact"]}>
								Vous êtes notaire et n'avez pas d'adresse e-mail @notaires.fr ?&nbsp;
								<a href="#lol" className={classes["underline-link"]}>
									Contactez-nous
								</a>
							</p>
						</Form>
					</div>
				</AuthTemplate>
				{this.state.success && (
					<Modal
						header={"Nous vous remercions pour votre demande d'inscription"}
						footer={"À très vite !"}
						hasCloseButton
						onClose={this.refreshPage}>
						<span>Notre service de vérification met tout en œuvre pour vous offrir l'accès à nos services dans les plus brefs délais.</span>
						<span>Vous recevrez un e-mail de confirmation.</span>
					</Modal>
				)}
			</>
		);
	}

	private refreshPage() {
		window.location.reload();
	}

	private async handleSubmit(
		e: React.FormEvent<HTMLFormElement> | null,
		values: Record<string, string>,
		onApiErrors: (apiFormErrors: IApiFormErrors | null) => void,
	) {
		try {
			const {
				email,
				password,
				confirmPassword,
				lastName,
				firstName,
				phoneNumber,
				officeDenomination,
				officeAddress,
				officeCity,
				officeZipCode,
				officeCRPCEN,
			} = values;
			if (
				!this.state.hasAcceptedGTCU ||
				!email ||
				!password ||
				!confirmPassword ||
				!lastName ||
				!firstName ||
				!phoneNumber ||
				!officeDenomination ||
				!officeAddress ||
				!officeCity ||
				!officeZipCode ||
				!officeCRPCEN
			) {
				return;
			}

			const { jwtPair } = await Register.getInstance().post({
				email,
				password,
				confirmPassword,
				lastName,
				firstName,
				phoneNumber,
				officeDenomination,
				officeAddress,
				officeCity,
				officeZipCode,
				officeCRPCEN,
			});
			if (jwtPair) {
				this.setState({ success: true });
			}
		} catch (error: any) {
			onApiErrors({
				hidden: SignUp.errorToTextMap[error.error.message] ?? I18n.translate(I18n.config.pages.sign_up.errors.error_in_register),
			});
		}
	}

	private toggleGTCU(): void {
		this.setState({ hasAcceptedGTCU: !this.state.hasAcceptedGTCU });
	}
}
