import React, { useEffect } from "react";
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";

// Components
import ToastHandler from "./Materials/ToastsHandler";
import Module from "./Elements/Module";

// Pages
import Home from "./Pages/Home";
import Administrators from "./Pages/Administrators";
import ArchivedFiles from "./Pages/ArchivedFiles";
import CollaboratorManagement from "./Pages/CollaboratorManagement";
import Dashboard from "./Pages/Dashboard";
import DocumentSettings from "./Pages/DocumentSettings";
import DocumentsToValidate from "./Pages/DocumentsToValidate";
import FileInProgress from "./Pages/FileInProgress";
import FileToStore from "./Pages/FileToStore";
import OfficeManagement from "./Pages/OfficeManagement";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import ResetPass from "./Pages/ResetPass";
import { AppRuleNames, AppRuleActions } from "Entities/AuthFactory/Rule";
import Rules, { RulesMode } from "./Materials/Rules";
import Logout from "./Pages/Logout";
import MyAccount from "./Pages/MyAccount";
import ChangePass from "./Pages/ChangePass";

type IProps = {};
type IState = {};

export default class Main extends React.Component<IProps, IState> {
	public override render(): JSX.Element {
		const pageConfig = Module.config.pages;
		return (
			<Router basename="/">
				<ToastHandler />

				<Routes>
					<Route element={<BindRouter />}>
						<Route
							element={
								<Module from={pageConfig.Login}>
									<Login />
								</Module>
							}
							path={pageConfig.Login.props.path.pathname}
						/>

						<Route
							element={
								<Module from={pageConfig.Logout}>
									<Logout />
								</Module>
							}
							path={pageConfig.Logout.props.path.pathname}
						/>

						<Route
							element={
								<Module from={pageConfig.SignUp}>
									<SignUp />
								</Module>
							}
							path={pageConfig.SignUp.props.path.pathname}
						/>

						<Route
							element={
								<Module from={pageConfig.ResetPassword}>
									<ResetPass />
								</Module>
							}
							path={pageConfig.ResetPassword.props.path.pathname}
						/>

						<Route
							element={
								<Module from={pageConfig.ChangePassword}>
									<ChangePass />
								</Module>
							}
							path={pageConfig.ChangePassword.props.path.pathname}
						/>

						<Route
							element={
								<Module from={pageConfig.MyAccount}>
									<MyAccount />
								</Module>
							}
							path={pageConfig.MyAccount.props.path.pathname}
						/>

						<Route
							element={
								<Rules isPage mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.readSelf }]}>
									<Home />
								</Rules>
							}
							path="/"
						/>

						<Route
							element={
								<Module from={pageConfig.Administrators}>
									<Rules isPage mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.readSelf }]}>
										<Administrators />
									</Rules>
								</Module>
							}
							path={pageConfig.Administrators.props.path.pathname}
						/>

						<Route
							element={
								<Module from={pageConfig.ArchivedFiles}>
									<Rules isPage mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.readSelf }]}>
										<ArchivedFiles />
									</Rules>
								</Module>
							}
							path={pageConfig.ArchivedFiles.props.path.pathname}
						/>

						<Route
							element={
								<Module from={pageConfig.CollaboratorManagement}>
									<Rules isPage mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.readSelf }]}>
										<CollaboratorManagement />
									</Rules>
								</Module>
							}
							path={pageConfig.CollaboratorManagement.props.path.pathname}
						/>

						<Route
							element={
								<Module from={pageConfig.Dashboard}>
									<Rules isPage mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.readSelf }]}>
										<Dashboard />
									</Rules>
								</Module>
							}
							path={pageConfig.Dashboard.props.path.pathname}
						/>

						<Route
							element={
								<Module from={pageConfig.DocumentSettings}>
									<Rules isPage mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.readSelf }]}>
										<DocumentSettings />
									</Rules>
								</Module>
							}
							path={pageConfig.DocumentSettings.props.path.pathname}
						/>

						<Route
							element={
								<Module from={pageConfig.DocumentsToValidate}>
									<Rules isPage mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.readSelf }]}>
										<DocumentsToValidate />
									</Rules>
								</Module>
							}
							path={pageConfig.DocumentsToValidate.props.path.pathname}
						/>

						<Route
							element={
								<Module from={pageConfig.FilesInProgress}>
									<Rules isPage mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.readSelf }]}>
										<FileInProgress />
									</Rules>
								</Module>
							}
							path={pageConfig.FilesInProgress.props.path.pathname}
						/>

						<Route
							element={
								<Module from={pageConfig.FilesToStore}>
									<Rules isPage mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.readSelf }]}>
										<FileToStore />
									</Rules>
								</Module>
							}
							path={pageConfig.FilesToStore.props.path.pathname}
						/>

						<Route
							element={
								<Module from={pageConfig.OfficeManagement}>
									<Rules isPage mode={RulesMode.NECESSARY} rules={[{ name: AppRuleNames.users, action: AppRuleActions.readSelf }]}>
										<OfficeManagement />
									</Rules>
								</Module>
							}
							path={pageConfig.OfficeManagement.props.path.pathname}
						/>

						<Route element={<Navigate to="/" replace />} path="*" />
					</Route>
				</Routes>
			</Router>
		);
	}

	public override componentDidMount() {
		//this.removeThemeModeOnSwitch = ThemeMode.getInstance().onSwitch(() => this.updateThemeMode());
	}

	public override componentWillUnmount() {
		this.removeThemeModeOnSwitch();
	}

	private removeThemeModeOnSwitch = () => {};

	private updateThemeMode() {
		//document.body.setAttribute("theme-mode", ThemeMode.getInstance().type);
	}
}

function BindRouter() {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
		//TopMenuStatus.getInstance().close();
		document.body.setAttribute("route", location.pathname);
	}, [location.pathname]);

	return <Outlet />;
}
