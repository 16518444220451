import { ReactNode } from "react";

// Components
import Module from "Components/Elements/Module";

// Entities
import { ERole } from "Entities/AuthFactory/Role";

// Stores
import { EMenuViewMode } from "Stores/MenuViewMode";

// Assets
import { ReactComponent as LogoutIcon } from "assets/images/icons/logout.svg";
import { ReactComponent as SwitchIcon } from "assets/images/icons/switch.svg";

export enum EMenuLinkType {
	NAV_LINK = "NAV_LINK",
	SEPARATOR = "SEPARATOR",
	VIEW_SWITCHER = "VIEW_SWITCHER",
}

export interface IMenuLink {
	menuLinkType: EMenuLinkType;
	enabled: boolean;
	text?: string;
	to?: string;
	icon?: ReactNode;
	menuViewMode?: EMenuViewMode;
}

interface IMenuLinks {
	[ERole.OFFICE]: IMenuLink[];
	[ERole.ADMIN]: IMenuLink[];
	[ERole.SUPER_ADMIN]: IMenuLink[];
}

export const menuLinks: IMenuLinks = {
	[ERole.OFFICE]: [
		{
			menuLinkType: EMenuLinkType.NAV_LINK,
			to: Module.config.pages.MyAccount.props.path.pathname,
			text: Module.config.pages.MyAccount.props.path.labelKey,
			enabled: Module.config.pages.MyAccount.enabled,
		},
		{
			menuLinkType: EMenuLinkType.SEPARATOR,
			enabled: true,
		},
		{
			menuLinkType: EMenuLinkType.NAV_LINK,
			to: Module.config.pages.Logout.props.path.pathname,
			text: Module.config.pages.Logout.props.path.labelKey,
			enabled: Module.config.pages.Logout.enabled,
			icon: <LogoutIcon />,
		},
	],
	[ERole.ADMIN]: [
		{
			menuLinkType: EMenuLinkType.NAV_LINK,
			to: Module.config.pages.MyAccount.props.path.pathname,
			text: Module.config.pages.MyAccount.props.path.labelKey,
			enabled: Module.config.pages.MyAccount.enabled,
		},
		{
			menuLinkType: EMenuLinkType.VIEW_SWITCHER,
			text: Module.config.pages.Administration.labelKey,
			enabled: Module.config.pages.Administration.enabled,
			menuViewMode: EMenuViewMode.ADMINISTRATION,
		},
		{
			menuLinkType: EMenuLinkType.VIEW_SWITCHER,
			text: Module.config.pages.Office.labelKey,
			enabled: Module.config.pages.Office.enabled,
			menuViewMode: EMenuViewMode.OFFICE,
		},
		{
			menuLinkType: EMenuLinkType.SEPARATOR,
			enabled: true,
		},
		{
			menuLinkType: EMenuLinkType.NAV_LINK,
			to: Module.config.pages.Logout.props.path.pathname,
			text: Module.config.pages.Logout.props.path.labelKey,
			enabled: Module.config.pages.Logout.enabled,
			icon: <LogoutIcon />,
		},
	],
	[ERole.SUPER_ADMIN]: [
		{
			menuLinkType: EMenuLinkType.NAV_LINK,
			to: Module.config.pages.MyAccount.props.path.pathname,
			text: Module.config.pages.MyAccount.props.path.labelKey,
			enabled: Module.config.pages.MyAccount.enabled,
		},
		{
			menuLinkType: EMenuLinkType.NAV_LINK,
			to: Module.config.pages.AnchorMonitoring.props.path.pathname,
			text: Module.config.pages.AnchorMonitoring.props.path.labelKey,
			enabled: Module.config.pages.AnchorMonitoring.enabled,
		},
		{
			menuLinkType: EMenuLinkType.SEPARATOR,
			enabled: true,
		},
		{
			menuLinkType: EMenuLinkType.NAV_LINK,
			to: Module.config.pages.SwitchAccount.props.path.pathname,
			text: Module.config.pages.SwitchAccount.props.path.labelKey,
			enabled: Module.config.pages.SwitchAccount.enabled,
			icon: <SwitchIcon />,
		},
		{
			menuLinkType: EMenuLinkType.NAV_LINK,
			to: Module.config.pages.Logout.props.path.pathname,
			text: Module.config.pages.Logout.props.path.labelKey,
			enabled: Module.config.pages.Logout.enabled,
			icon: <LogoutIcon />,
		},
	],
};
