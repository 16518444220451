import React, { ReactNode } from "react";

// Components
import Footer from "Components/Materials/Footer";
import Header from "Components/Materials/Header";
import Module from "Components/Elements/Module";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	title: string;
	children?: ReactNode;
};
type IState = {};

export default class DefaultTemplate extends React.Component<IProps, IState> {
	public override render(): JSX.Element {
		return (
			<>
				<Module from={Module.config.Header}>
					<Header />
				</Module>
				<div className={classes["root"]}>{this.props.children}</div>
				<Module from={Module.config.Footer}>
					<Footer />
				</Module>
			</>
		);
	}

	override componentDidMount() {
		window.document.title = this.props.title;
	}
}
