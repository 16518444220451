import React, { ReactNode } from "react";

// Components
import Logo from "Components/Materials/Logo";

// Assets
import AnimateLogo from "assets/images/logos/animate-logo.gif";
import AnimateLogo2 from "assets/images/logos/animate-logo-2.gif";
import StaticLogo from "assets/images/logos/not-it-logo-static.png";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	title: string;
	children?: ReactNode;
};
type IState = {};

export default class DefaultTemplate extends React.Component<IProps, IState> {
	public override render(): JSX.Element {
		return (
			<div className={classes["root"]}>
				<div className={classes["left"]}>
					<Logo isBig classname={classes["logo"]} />
					{this.props.children}
					<img src={StaticLogo} className={classes["static-logo"]} alt="static logo" />
				</div>

				<div className={classes["right"]}>
					<img src={AnimateLogo} className={classes["animate-logo"]} alt="first animated logo" />
					<img src={AnimateLogo2} className={classes["animate-logo-2"]} alt="second animated logo" />
				</div>
			</div>
		);
	}

	override componentDidMount() {
		window.document.title = this.props.title;
	}
}
