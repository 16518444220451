// Components
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate/DefaultTemplate";
import BasePage from "../Base";

// Styles
import classes from "./classes.module.scss";

type IProps = {};
type IState = {};

export default class Dashboard extends BasePage<IProps, IState> {
	override render() {
		return (
			<DefaultTemplate title="Dashboard">
				<div className={classes["root"]}>Dashboard</div>
			</DefaultTemplate>
		);
	}
}
