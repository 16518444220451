import { Component, ReactNode } from "react";

// Assets
import { ReactComponent as Cross } from "assets/images/icons/cross.svg";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	header?: string | ReactNode;
	footer?: ReactNode;
	hasCloseButton?: boolean;
	backgroundClickDisabled?: boolean;
	onClose?: () => void;
	className?: string;
	children?: ReactNode;
};
type IState = {
	isOpen: boolean;
};

export default class Modal extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			isOpen: true,
		};

		this.closeModalOnBackgroundClick = this.closeModalOnBackgroundClick.bind(this);
		this.closePopup = this.closePopup.bind(this);
	}

	override render() {
		if (!this.state.isOpen) return null;

		return (
			<div className={classes["root"]}>
				<div className={classes["background"]} onClick={this.closeModalOnBackgroundClick} />
				<div className={classes["container"]}>
					{this.props.hasCloseButton && <Cross className={classes["cross"]} onClick={this.closePopup} />}
					<div className={classes["sub-container"]}>
						<h2>{this.props.header}</h2>
						{this.props.children}
						{this.props.footer}
					</div>
				</div>
			</div>
		);
	}

	private closePopup() {
		this.setState({
			isOpen: false,
		});
		this.props.onClose?.();
	}

	private closeModalOnBackgroundClick() {
		if (this.props.backgroundClickDisabled) return;
		this.closePopup();
	}
}
