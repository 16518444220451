import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";
import { IAppUser } from "Entities/AuthFactory/User";

export default class MyAccount extends BaseApiService {
	private static instance: MyAccount;
	protected static readonly baseUrl = Config.getInstance().get().api.notIT.concat("/app-user/", Config.getInstance().get().app);

	public static getInstance() {
		if (!MyAccount.instance) {
			MyAccount.instance = new MyAccount();
		}

		return MyAccount.instance;
	}

	public async get(): Promise<IAppUser> {
		const url = new URL(MyAccount.baseUrl.concat("/myAccount"));
		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
