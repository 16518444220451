import { Component } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

// Components
import { INavLink } from "./NavLinks";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	navLinks: INavLink[];
};
type IState = {};

export default class HeaderNav extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.isActive = this.isActive.bind(this);
	}

	override render() {
		return (
			<nav className={classes["root"]}>
				{this.props.navLinks.map(
					(link) =>
						link.enabled && (
							<NavLink
								key={link.to}
								to={link.to}
								className={classNames(classes["link"], {
									[classes["active"]!]: this.isActive(link.to),
								})}>
								{link.text}
							</NavLink>
						),
				)}
			</nav>
		);
	}

	private isActive(path: string): boolean {
		return window.location.pathname === path;
	}
}
