import Module from "Components/Elements/Module";
import { ERole } from "Entities/AuthFactory/Role";

export interface INavLink {
	to: string;
	text: string;
	enabled: boolean;
}

interface INavLinks {
	[ERole.OFFICE]: INavLink[];
	[ERole.ADMIN]: INavLink[];
	[ERole.SUPER_ADMIN]: INavLink[];
}

export const navLinks: INavLinks = {
	[ERole.OFFICE]: [
		{
			to: Module.config.pages.FilesInProgress.props.path.pathname,
			text: Module.config.pages.FilesInProgress.props.path.labelKey,
			enabled: Module.config.pages.FilesInProgress.enabled,
		},
		{
			to: Module.config.pages.DocumentsToValidate.props.path.pathname,
			text: Module.config.pages.DocumentsToValidate.props.path.labelKey,
			enabled: Module.config.pages.DocumentsToValidate.enabled,
		},
		{
			to: Module.config.pages.FilesToStore.props.path.pathname,
			text: Module.config.pages.FilesToStore.props.path.labelKey,
			enabled: Module.config.pages.FilesToStore.enabled,
		},
		{
			to: Module.config.pages.ArchivedFiles.props.path.pathname,
			text: Module.config.pages.ArchivedFiles.props.path.labelKey,
			enabled: Module.config.pages.ArchivedFiles.enabled,
		},
	],
	[ERole.ADMIN]: [
		{
			to: Module.config.pages.CollaboratorManagement.props.path.pathname,
			text: Module.config.pages.CollaboratorManagement.props.path.labelKey,
			enabled: Module.config.pages.CollaboratorManagement.enabled,
		},
		{
			to: Module.config.pages.DocumentSettings.props.path.pathname,
			text: Module.config.pages.DocumentSettings.props.path.labelKey,
			enabled: Module.config.pages.DocumentSettings.enabled,
		},
	],
	[ERole.SUPER_ADMIN]: [
		{
			to: Module.config.pages.OfficeManagement.props.path.pathname,
			text: Module.config.pages.OfficeManagement.props.path.labelKey,
			enabled: Module.config.pages.OfficeManagement.enabled,
		},
		{
			to: Module.config.pages.Dashboard.props.path.pathname,
			text: Module.config.pages.Dashboard.props.path.labelKey,
			enabled: Module.config.pages.Dashboard.enabled,
		},
		{
			to: Module.config.pages.DocumentSettings.props.path.pathname,
			text: Module.config.pages.DocumentSettings.props.path.labelKey,
			enabled: Module.config.pages.DocumentSettings.enabled,
		},
		{
			to: Module.config.pages.Administrators.props.path.pathname,
			text: Module.config.pages.Administrators.props.path.labelKey,
			enabled: Module.config.pages.Administrators.enabled,
		},
	],
};
