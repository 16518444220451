// Components
import Button, { EButtonSize, EButtonType, EButtonVariant } from "Components/Elements/Button";
import InputField from "Components/Elements/Form/Elements/TextField";
import BasePage from "Components/Pages/Base";
import AuthTemplate from "Components/PageTemplates/AuthTemplate/AuthTemplate";

// Styles
import classes from "./classes.module.scss";
import Form, { IApiFormErrors } from "Components/Elements/Form";
import I18n from "Components/Elements/I18n";
import ResetPassword from "Api/NotIT/AppAuth/ResetPassword";
import Modal from "Components/Elements/Modal";

type IProps = {};
type IState = {
	loading: boolean;
	success: boolean;
	email: string | null;
};

export default class ResetPass extends BasePage<IProps, IState> {
	public constructor(props: IProps) {
		super(props);
		this.state = {
			loading: false,
			success: false,
			email: "notaire1@notaires.fr",
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<>
				<AuthTemplate title="Connexion">
					<div className={classes["root"]}>
						<h1 className={classes["title"]}>Mot de passe oublié ?</h1>

						<div className={classes["description"]}>Entrez votre adresse mail pour réinitialiser votre mot de passe.</div>

						<Form onSubmit={this.handleSubmit} className={classes["form"]}>
							<InputField containerClassName={classes["fields"]} required name="email" type="email" placeholder={"Email"} emailOnly />

							<InputField containerClassName={classes["hidden-input"]} type="hidden" name="hidden" onChange={() => {}} />

							<div className={classes["button_container"]}>
								<Button variant={EButtonVariant.PURPLE} size={EButtonSize.LARGE} type={EButtonType.SUBMIT}>
									Réinitialiser le mot de passe
								</Button>
							</div>
						</Form>
					</div>
				</AuthTemplate>
				{this.state.success && (
					<Modal header={"Réinitialiser votre mot de passe"} hasCloseButton>
						<div>Un lien pour réinitialiser votre mot de passe a été envoyé à {this.state.email}</div>
					</Modal>
				)}
			</>
		);
	}

	private async handleSubmit(
		e: React.FormEvent<HTMLFormElement> | null,
		values: Record<string, string>,
		onApiErrors: (apiFormErrors: IApiFormErrors | null) => void,
	) {
		try {
			const { email } = values;
			if (!email) return;
			const status = await ResetPassword.getInstance().sendCode({ email });
			this.setState({ success: status, email });
		} catch (error: any) {
			onApiErrors({ hidden: I18n.translate(I18n.config.pages.reset_password.errors.error_send_mail) });
		}
	}
}
