import { createRoot } from "react-dom/client";
import Main from "./Components/Main";
import AutoLoadServices from "Services/AutoLoadServices";
import "./index.scss";

AutoLoadServices.load();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<Main />);
