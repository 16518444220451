import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";
import { JwtPair } from "Entities/AuthFactory/AccessToken";

export interface IPresignPostRequest {
	jwtPair: JwtPair;
}

export default class Presign extends BaseApiService {
	private static instance: Presign;
	protected static readonly baseUrl = Config.getInstance()
		.get()
		.api.notIT.concat("/app-auth/", Config.getInstance().get().app.concat("/presign"));

	public static getInstance() {
		if (!Presign.instance) {
			Presign.instance = new Presign();
		}

		return Presign.instance;
	}

	public async post(): Promise<IPresignPostRequest> {
		const url = new URL(Presign.baseUrl);
		try {
			return this.postRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
