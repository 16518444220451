export interface IAppRule {
	name: AppRuleNames;
	action: AppRuleActions;
}

export enum AppRuleActions {
	signinSelf = "signin-self",
	createSelf = "create-self",
	readSelf = "read-self",
}

export enum AppRuleNames {
	users = "users",
}
