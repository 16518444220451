import { ChangeEvent, Component, createRef, FocusEventHandler } from "react";

// elements
import Validators, { IValidationTypes } from "Components/Elements/Form/Validators";
import { FormContext, IFormContext } from "Components/Elements/Form";
import I18n from "Components/Elements/I18n";

export type IError = {
	message: string;
	validator: string;
	value: string;
	args: any[];
};

export type IProps = IValidationTypes & {
	value?: string;
	defaultValue?: string;
	onChange?: (event: ChangeEvent<HTMLInputElement & HTMLSelectElement & HTMLTextAreaElement>) => void;
	onBlur?: FocusEventHandler<HTMLInputElement>;
	name: string;
	regex?: RegExp;
	onCancel?: () => void;
	onError?: (errors: IError[]) => void;
	isReadOnly?: boolean;
};
type IState = {
	value: string;
	errors: IError[];
};

export default abstract class BaseField<P extends IProps, S extends IState = IState> extends Component<P, S> {
	public static override contextType = FormContext;
	public override context: IFormContext | null = null;
	public fieldRef: React.RefObject<any>;

	constructor(props: P) {
		super(props);
		this.fieldRef = createRef();

		this.onChange = this.onChange.bind(this);
		this.renderErrors = this.renderErrors.bind(this);
		this.validate = this.validate.bind(this);
		this.focus = this.focus.bind(this);
	}

	public override componentDidMount() {
		this.context?.setField(this.props.name, this);
	}

	public override componentWillUnmount() {
		this.context?.unSetField(this.props.name);
	}

	public validate() {
		if (this.props.isReadOnly) return;
		const props: { [key: string]: any } = this.props;
		const validators = Object.entries(Validators).filter(([key]) => props[key]);

		const unValidateds = validators.filter(([key, validator]) => {
			return !(validator.validate as any)(this.state.value, ...(props[key].args ?? []));
		});

		const errors: IError[] = unValidateds.map(([key, unValidated]) => {
			let message = unValidated.message;
			if (typeof props[key] === "object" && props[key].message) message = props[key].message;
			return { message, validator: key, value: this.state.value, args: props[key].args ?? [] };
		});

		this.setErrors(errors);
		return errors;
	}

	public setErrors(errors: IError[]) {
		this.setState({ ...this.state, errors });
		this.onError(errors);
	}

	public focus() {
		const field = this.fieldRef.current;

		if (field) {
			try {
				field.scrollIntoView({
					behavior: "smooth",
					inline: "center",
					block: "center",
				});
			} catch (error) {}

			// wait until the scrolling is over to focus on the field.
			// It also covers the case of old browsers (for example: IOS Opera)
			// that do not support the scrolling function.
			let isScrolling = false;
			let interval: NodeJS.Timer | null = null;

			window.addEventListener("scroll", () => {
				isScrolling = true;
			});

			const waitUntilScrollEnd = () => {
				if (isScrolling) {
					isScrolling = false;
				} else {
					if (typeof interval === "number") {
						clearInterval(interval);
					}

					field.focus();
				}
			};

			interval = setInterval(waitUntilScrollEnd, 100);
		}
	}

	protected onChange(event: ChangeEvent<HTMLInputElement & HTMLSelectElement & HTMLTextAreaElement>) {
		if (this.props.regex) {
			if (!this.props.regex.test(event.currentTarget.value)) {
				event.currentTarget.value = event.currentTarget.value.substring(0, event.currentTarget.value.length - 1);
			}
		}
		this.context?.onFieldChange(this.props.name, this);
		this.setState({ value: event.currentTarget.value }, () => {
			this.validate();
		});
		this.props.onChange?.(event);
	}

	protected renderErrors(errors: IError[]): JSX.Element[] {
		return errors.map((error) => {
			const vars: { [key: number]: string } = { 0: error.value };
			error.args.forEach((arg, index) => {
				vars[index + 1] = arg;
			});

			return (
				<I18n
					map={error.message}
					key={error.message}
					vars={vars}
					content={(localizedErrorMessage) =>
						localizedErrorMessage.map((text) => (
							<p className="validation-error-message" key={error.message}>
								{text}
							</p>
						))
					}
				/>
			);
		});
	}

	protected autoRenderErrors(): JSX.Element[] {
		return this.renderErrors(this.state.errors);
	}

	public onError(errors: IError[]) {
		this.props.onError && this.props.onError(errors);
	}

	/**
	 * It is automatically called by the parent form when the user cancelled the
	 * form and all of its changes.
	 *
	 * Override the method for custom cancelling logic, or pass a custom onCancel
	 * callback.
	 */
	public cancel() {
		const cancelCallback = this.props.onCancel;

		if (cancelCallback) {
			cancelCallback();
		}
	}
}
