import React from "react";

// Components
import Logo from "Components/Materials/Logo";
import HeaderNav from "./HeaderNav";
import ProfileMenu from "./ProfileMenu";
import { menuLinks } from "./ProfileMenu/MenuLinks";
import { navLinks } from "./HeaderNav/NavLinks";
import BurgerMenu from "./BurgerMenu";

// Entities
import { ERole } from "Entities/AuthFactory/Role";

// Stores
import MenuViewMode, { EMenuViewMode } from "Stores/MenuViewMode";

// Styles
import classes from "./classes.module.scss";

type IProps = {};
type IState = {
	userRole: ERole;
	menuViewMode: ERole;
};

export default class Header extends React.Component<IProps, IState> {
	private onMenuViewModeRemove = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			userRole: ERole.ADMIN,
			menuViewMode: ERole.ADMIN,
		};

		this.setRole = this.setRole.bind(this);
	}

	public override render(): JSX.Element {
		return (
			<header className={classes["root"]}>
				<div className={classes["content"]}>
					<Logo />
					<HeaderNav navLinks={navLinks[this.state.menuViewMode]} />
					<ProfileMenu menuLinks={menuLinks[this.state.userRole]} />
					<BurgerMenu navLinks={navLinks[this.state.menuViewMode]} menuLinks={menuLinks[this.state.userRole]} />
				</div>
			</header>
		);
	}

	override componentDidMount(): void {
		this.onMenuViewModeRemove = MenuViewMode.getInstance().onSwitch(this.setRole);
		const menuViewMode = MenuViewMode.getInstance().menuViewMode;
		this.setRole(menuViewMode);
	}

	override componentWillUnmount(): void {
		this.onMenuViewModeRemove();
	}

	private setRole(menuViewMode: EMenuViewMode): void {
		const userRole = this.state.userRole; // TODO: Get user role from store

		if (userRole === ERole.ADMIN) {
			this.setState({ menuViewMode: menuViewMode === EMenuViewMode.OFFICE ? ERole.OFFICE : ERole.ADMIN });
		}
		this.setState({ userRole });
	}
}
