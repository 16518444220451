import { Component } from "react";
import { NavLink } from "react-router-dom";

// Components
import { EMenuLinkType, IMenuLink } from "./MenuLinks";

// Assets
import { ReactComponent as ProfileIcon } from "assets/images/icons/profile.svg";

// Stores
import MenuViewMode, { EMenuViewMode } from "Stores/MenuViewMode";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	menuLinks: IMenuLink[];
};
type IState = {
	isMenuOpen: boolean;
};

export default class ProfileMenu extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			isMenuOpen: false,
		};

		this.toggleMenu = this.toggleMenu.bind(this);
	}

	override render() {
		return (
			<>
				<div className={classes["root"]}>
					<ProfileIcon className={classes["profile-icon"]} onClick={this.toggleMenu} />

					{this.state.isMenuOpen && <div className={classes["menu"]}>{this.props.menuLinks.map((element, index) => this.getElement(element, index))}</div>}
				</div>

				{this.state.isMenuOpen && <div className={classes["background"]} onClick={this.toggleMenu} />}
			</>
		);
	}

	private toggleMenu() {
		this.setState((prevState) => ({
			isMenuOpen: !prevState.isMenuOpen,
		}));
	}

	private getElement(element: IMenuLink, index: number) {
		switch (element.menuLinkType) {
			case EMenuLinkType.NAV_LINK:
				return this.renderMenuLink(element, index);
			case EMenuLinkType.SEPARATOR:
				return this.renderMenuSeparator(index);
			case EMenuLinkType.VIEW_SWITCHER:
				return this.renderViewSwitcher(element, index);

			default:
				return null;
		}
	}

	private renderMenuLink(element: IMenuLink, key: number) {
		if (!element.to) return null;

		return (
			<NavLink to={element.to} className={classes["link"]} key={key}>
				{element.text}
				{element.icon}
			</NavLink>
		);
	}

	private renderMenuSeparator(key: number) {
		return <div className={classes["separator"]} key={key} />;
	}

	private renderViewSwitcher(element: IMenuLink, key: number) {
		const menuViewModeFromElement = element.menuViewMode;
		const menuViewModeFromStore = MenuViewMode.getInstance().menuViewMode;
		if (!menuViewModeFromElement || menuViewModeFromElement === menuViewModeFromStore) return null;

		return (
			<span className={classes["link"]} onClick={() => this.onViewSwitcherClick(menuViewModeFromElement)} key={key}>
				{element.text}
			</span>
		);
	}

	private onViewSwitcherClick(menuViewMode: EMenuViewMode) {
		this.switchMenuViewMode(menuViewMode);
		this.toggleMenu();
	}

	private switchMenuViewMode(menuViewMode: EMenuViewMode) {
		MenuViewMode.getInstance().menuViewMode = menuViewMode;
	}
}
