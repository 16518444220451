// import User from "Api/Factory/AppUser/User";
import { isEmail, isNotEmpty, isNumberString, isString, max, maxLength, min, minLength } from "class-validator";

const Validators = {
	/**
	 * **Parameters** : boolean
	 *
	 * This validator verifies the value is not empty
	 */
	required: { validate: isNotEmpty, message: "validation_messages.field_required" },

	/**
	 * **Parameters** : boolean
	 *
	 * This validator verifies the value is a number
	 */
	numbersOnly: { validate: isNumberString, message: "validation_messages.only_numbers" },

	/**
	 * **Parameters** : boolean
	 *
	 * This validator verifies the value is a number
	 */
	intOnly: {
		validate: function (value: string) {
			const regex = /^[0-9]*$/;
			return regex.test(value);
		},
		message: "validation_messages.only_integers",
	},

	/**
	 * **Parameters** : number
	 *
	 * This validator verifies the number is not below the parameter
	 */
	minNumber: { validate: (value: string, minVal: number) => min(Number(value), minVal), message: "validation_messages.below_min" },

	/**
	 * **Parameters** : number
	 *
	 * This validator verifies the number is not above the parameter
	 */
	maxNumber: { validate: (value: string, maxVal: number) => max(Number(value), maxVal), message: "validation_messages.above_max" },

	/**
	 * **Parameters** : number
	 *
	 * This validator verifies the string minimum length is conform to the parameter
	 */
	minLength: {
		validate: minLength,
		message: "validation_messages.min_string_length",
	},

	/**
	 * **Parameters** : number
	 *
	 * This validator verifies the string maximum length is conform to the parameter
	 */
	maxLength: {
		validate: maxLength,
		message: "validation_messages.max_string_length",
	},
	/**
	 * **Parameters** : boolean
	 *
	 * This validator verifies the input's value is a string.
	 */
	isString: { validate: (value: string) => isString(value), message: "validation_messages.only_letters" },

	/**
	 * **Parameters** : boolean
	 *
	 * This validator verifies the input's value is conform to the tag regex.
	 */
	isTag: {
		validate: function (value: string) {
			const regex = /^[a-zA-Z0-9][a-zA-Z0-9 ]*(,[a-zA-Z0-9][a-zA-Z0-9 ]*)*$/;
			const isValid = regex.test(value);
			if (!isValid) return false;

			const splittedTag = value.split(",");
			if (splittedTag.length !== new Set(splittedTag).size) {
				return false;
			}

			return true;
		},
		message: "validation_messages.not_valid_tag",
	},
	/**
	 * **Parameters** : boolean
	 *
	 * This validator verifies the input's value is a valid email.
	 *
	 * If the **input is empty, it is considered valid**. If you do not wish this
	 * to happen please refer to the `required` validator.
	 */
	emailOnly: {
		validate: (value: string) => (Boolean(value) ? isEmail(value) : true),
		message: "validation_messages.invalid_email",
	},

	isPseudo: {
		validate: (value: string) => {
			const pseudoRegex = /^[a-zA-Z][a-zA-Z0-9_-\s]{2,19}$/;
			return pseudoRegex.test(value);
		},
		message: "validation_messages.is_pseudo",
	},

	positiveNumber: {
		validate: (value: string) => {
			let nbr = parseFloat(value);
			return !(isNaN(nbr) || nbr <= 0);
		},
		message: "validation_messages.positive_number",
	},

	floatPrecision: {
		validate: (value: string, precision: number) => {
			// If value is not a float
			if (isNaN(parseFloat(value))) return false;
			let splittedValue = value.split(".");
			// If there is no decimals
			if (!splittedValue[1]) return true;
			// If there is more decimals than the required precision
			if (splittedValue[1].length > precision) return false;
			return true;
		},
		message: "validation_messages.float_precision",
	},

	url: {
		validate: (value: string, root: string) => {
			try {
				const url = new URL(value);
				if (root) {
					if (url.hostname === root || url.hostname === `www.${root}`) {
						return true;
					}
					return false;
				}
				return true;
			} catch (e) {
				return false;
			}
		},
		message: "validation_messages.invalid_url",
	},

	/*
	uniqueUsername: {
		validate: async (value: string, actual: string) => {
			const user = await User.getInstance().getOneByPseudo(value);
			if (!user) return true;
			if (user.app_user_info?.pseudo === actual) return true;
			return false;
		},
		message: "validation_messages.unique_pseudo",
	},
	*/

	/*
	uniqueEmail: {
		validate: async (value: string, actual: string) => {
			const user = await User.getInstance().getOneByEmail(value);
			if (!user) return true;
			if (user.app_user_info?.email === actual) return true;
			return false;
		},
		message: "validation_messages.unique_email",
	},
	*/
};

export default Validators;
export type IValidationTypes = Partial<
	Record<keyof typeof Validators, boolean | Partial<{ message: string; args: any[]; isErrored: (errored: boolean) => void }>>
>;
